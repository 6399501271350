@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.cdnfonts.com/css/metropolis-2);
@font-face{font-family:'metropolis';font-style:normal;font-weight:400; src: url('./assets/fonts/Metropolis-Regular.woff') format('woff2'),
  url('./assets/fonts/Metropolis-Regular.woff') format('woff');}


@font-face{font-family:'metropolis';font-style:normal;font-weight:500; src: url('./assets/fonts/Metropolis-Medium.woff') format('woff2'),
  url('./assets/fonts/Metropolis-Medium.woff') format('woff');}
@font-face{font-family:'metropolis';font-style:normal;font-weight:600; src: url('./assets/fonts/Metropolis-SemiBold.woff') format('woff2'),
  url('./assets/fonts/Metropolis-SemiBold.woff') format('woff');}
@font-face{font-family:'metropolis';font-style:normal;font-weight:700; src: url('./assets/fonts/Metropolis-Bold.woff') format('woff2'),
  url('./assets/fonts/Metropolis-Bold.woff') format('woff');}

@font-face{font-family:'metropolis';font-style:normal;font-weight:800; src: url('./assets/fonts/Metropolis-ExtraBold.woff') format('woff2'),
  url('./assets/fonts/Metropolis-ExtraBold.woff') format('woff');}


body{font-family:'Inter',sans-serif}
p{font-size:18px}
h1{
  font-size: 40px;
  font-weight: 700;
}
h2{
  font-size:30px;font-weight:700;
}
h3{
  font-size: 24px;
  font-weight: 600;
}
h4{
  font-size:20px;font-weight:500;
}
h1,h2,h3,h4,.btn{font-family:'metropolis'}
.btn-theme{background:#0fc1b7;color:#fff;font-size:20px;border:1px solid #0fc1b7;padding:5px 20px;height:50px;display:inline-flex;align-items:center;justify-content:center;font-weight: 500;}
.btn-theme:hover{background:0 0;color:#0fc1b7;border-color:#0fc1b7}
.main{
  overflow: hidden;
}
.header{position:fixed;background:rgba(72,87,118,.2);top:0;z-index:999;left:0;width:100%}
.header .navbar{background-color:rgba(72,87,118,.2)!important}
.header .navbar .nav-link{color:#fff;font-weight:500;font-size:17px;padding:5px 0;margin-right:40px;padding-left:0;border-bottom:3px solid transparent}
.header .navbar .nav-link:hover{border-color:#0fc1b7}
.header .nav-item .dropdown-item:hover{background:#0fc1b7;color:#fff}
.header .navbar-toggler {
  box-shadow: none;
  padding: 4px 7px;
  background: #fff;
}
.react-slideshow-wrapper.slide .slider-item {
  position: relative;
}
.main-banner .banner-content {
  transform: none;
  top: 0;
  left: 0;
  width: 100%;
  position: relative;
  max-width: 80%;
}
.main-banner .banner-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.main-banner .react-slideshow-container .default-nav ,.testimonial-section .react-slideshow-container .default-nav{
  height: 40px;
  width: 40px;
  padding: 0;
}
.testimonial-section .react-slideshow-container .default-nav svg{
  width: 20px;
}
.page-header{position:relative;width:100%;height:400px;overflow:hidden}
.page-header-video{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:-1}
.banner-content{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;color:#fff;z-index:1}
.content1{background-color:#fff;background-image:url(assets/images/canvas.png);background-size:contain;background-repeat:no-repeat;background-position:center}
.content2{background-image:url(assets/images/lightpaper-bg.jpg)}
.content3{position:relative;width:100%;height:100vh;overflow:hidden;background-color:transparent}
.main-banner{position:relative}
.main-banner .slider-item .video {
  width: 100%;
  min-height: 500px;
  height: 100%;
  object-fit: cover;
}
.banner-overlay{position:absolute;width:100%;left:0;top:0;height:100%;background:#00000063;display: flex;align-items: center;justify-content: center;}
.banner-overlay .banner-content{
  max-width: 80%;
  position: unset;
  transform: none;
}
.intro-section{padding:70px 0;background:#e5ebf9;padding-bottom:200px}
.intro-section .custom-box{border-radius:8px;padding:20px;height:100%;box-shadow:0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);background-color:#2a354b;color:#fff}
.custom-box.right-box{display:flex;flex-direction:column;align-items:center;justify-content:center}
.intro-section h2{margin-bottom:30px}
.intro-section h4{margin-top:30px}
.intro-section p{margin-bottom:30px}
.box.bg-blue{background-color:#2a354b;color:#fff}
.services-section{margin-top:-100px;padding-bottom:70px}
.services-section .box{box-shadow:0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);padding:20px;border-radius:20px;height:100%}
.services-section .box h2{margin-bottom:20px;}
.services-section .box p{margin-bottom:20px}
.services-list ul{list-style:none;margin:0;padding:0;display:flex;flex-wrap:wrap}
.services-list ul li{width:50%}
.services-list ul li a{color:#000;text-decoration:none;font-size:18px;display:flex;padding:10px;align-items: center;}
.services-list ul li a:hover{box-shadow:0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);border-radius:5px}
.services-list ul li .image-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.services-list ul li .image-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.trusted-section {
  position: relative;
}
.trusted-section .video video {
  width: 100%;
}
.logo-slider-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  background: rgb(72 87 118 / 59%);
}
.logo-slider-area h2 {
  color: #fff;
  margin-bottom: 30px;
}
.logo-slider .item .image {
  height: 100px;
  padding: 0 20px;
}
.logo-slider .item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.counter-section {
  padding: 50px 0;
}
.counter-section .box-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 10px 50px rgba(0,0,0,.08), 0 13px 45px rgba(0,0,0,.08);
  border-radius: 15px;
  flex-wrap: wrap;
}

.counter-section .box-wrapper .item {
  width: 25%;
  text-align: center;
  padding: 10px 15px;
}

.counter-section .box-wrapper .item .icon {
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}

.counter-section .box-wrapper .item .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.box-wrapper .item h2{
  color: #2a354b;
}
.box-wrapper .item p{
  color: #2a354b;
  margin: 0;
  font-weight: 600;
}
.footer {
  background: rgba(19, 31, 55, 1);
  padding: 50px 0 0;
  color: #fff;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.footer ul li {
  margin-bottom: 10px;
}
.footer ul li .icon {
  margin-right: 6px;
  font-size: 20px;
}
.footer ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  opacity: 0.8;
}

.footer ul li a:hover {
  opacity: 1;
}
.social-links ul {
    margin: 0;
    display: flex;
    align-items: center;
}

.social-links ul li {
    margin-bottom: 0;
    margin-right: 20px;
}

.social-links ul li a {
    font-size: 24px;
}
.copyrights {
  border-top: 1px solid #5f5f5f;
  padding: 10px 0;
  text-align: center;
}
.copyrights p{
  margin: 0;
  font-size: 14px;
}

.services-tabs-list {
  margin-top: 40px;
  margin-bottom: 50px;
}
.services-tabs-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.services-tabs-list ul li {
  margin-right: 20px;
  margin-bottom: 10px;
}

.services-tabs-list ul li a {
  color: #000;
  text-decoration: none;
  padding: 6px 10px;
  border: 1px solid;
  display: inline-block;
  border-radius: 4px;
  font-size: 18px;
}
.services-tabs-list ul li a:hover {
  background-color: #2a354b;
  color: #fff;
}
.service-item{
  margin-bottom: 50px;
}
.testimonial-section{
  background-color: rgba(19, 31, 55, 0.9);
  padding: 50px 0;
  text-align: center;
}
.testimonial-section p{
  font-size: 18px;
}
.testimonial-section h4{
  font-size: 20px;
}
.contact-page {
  padding: 50px 0;
}

.contact-page h2 {
  margin-bottom: 30px;
}
.contact-page .form-field {
  margin-bottom: 20px;
}

.contact-page .form-field .form-control {
  border: 1px solid #ccc;
  box-shadow: none;
  outline: none;
}

.contact-info ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 30px;
}

.contact-info ul li a {
  text-decoration: none;
  color: #000;
}

.contact-info ul li {
  margin-bottom: 10px;
}
.offices-section,.about-video{
  background-color: rgba(19, 31, 55, 0.9);
  padding: 50px 0;
  text-align: center;
}
.offices-section .image{
  height: 100%;
  max-height: 500px;
}
.offices-section .image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.potfolio-page{
  padding: 50px 0;
}

.porfolio-image {
  margin-top: 40px;
}
.porfolio-image .image {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.portfolio-section{
  padding: 50px 0;
}
.portfolio-section .custom-box {
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  background-color: #2a354b;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
}
.portfolio-section h2{
  margin-bottom: 20px;
}
.service-detail-page{
  padding: 50px 0;
}

.technologies-banner {
  padding-top: 100px;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  position: relative;
}
.technologies-banner::before {
  content: '';
  position: absolute;
  bottom: 0;
  height: 160px;
  background: linear-gradient(0, #8c9ab9a1 60%, transparent);
  width: 100%;
}
.technologies-banner .content{
  padding-right: 40px;
  padding-top: 50px;
}
.technologies-banner .content p{
  font-size: 20px;
  
}
.hirng-steps {
  text-align: center;
  padding: 50px 0;
  background: #e5ebf9;
}
.hirng-steps .steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hirng-steps .steps .item {
  display: flex;
  margin-right: 100px;
  text-align: left;
  position: relative;
}

.hirng-steps .steps .item:last-child {
  margin: 0;
}
.hirng-steps .steps .item::after {
  content: '>';
  position: absolute;
  right: -60px;
  font-size: 30px;
  top: 50%;
  transform: translate(0, -50%);
  color: #979797;
}
.hirng-steps .steps .item:last-child::after {
  display: none;
}
.hirng-steps .steps .icon {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.hirng-steps .steps .icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.hirng-steps h2 {
  margin-bottom: 30px;
}

.hirng-steps .steps h4 {
  margin: 0;
  font-weight: bold;
}


.hirng-steps .steps p {
  margin: 0;
}
@media screen and (max-width:1199px){
  h1{
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 18px;
  }
  p {
    font-size: 16px;
}
.btn-theme {
  font-size: 18px;
  padding: 5px 15px;
  height: 45px;
}
.services-list ul li a {
  font-size: 16px;
  padding: 8px;
}
.logo-slider .item .image{
  height: 70px;
  padding: 0 15px;
}
.footer ul li a{
  font-size: 14px;
}
.social-links ul li a {
  font-size: 20px;
}
.footer ul li .icon{
  font-size: 18px;
}
}
@media screen and (max-width:991px){
  .header .navbar-collapse {
    background: rgb(72 87 118);
  }
  .header .navbar .nav-link {
    padding: 5px 10px;
    margin-right: 0;
    border-bottom: 3px solid transparent;
  }
  .counter-section .box-wrapper .item {
    width: 50%;
  }
  .technologies-banner .content {
    padding-right: 0;
    padding-top: 20px;
}
.technologies-banner .content p {
  font-size: 18px;
}
}
@media screen and (max-width:767px){
  .main-banner .react-slideshow-container .default-nav svg {
    width: 14px;
}
.main-banner .react-slideshow-container .default-nav {
  height: 30px;
  width: 30px;
}
  .intro-section{
    padding: 50px 0;
    padding-bottom: 20px;
  }
.intro-section .custom-box{
margin-bottom: 30px;
height: auto;
}
.services-section {
  margin-top: 0;
  padding-bottom: 20px;
  padding-top: 50px;
}
.services-section .box{
  height: auto;
  margin-bottom: 30px;
}
.contact-info{
  margin-top: 30px;
}
.contact-page {
  padding: 30px 0 0;
}
.hirng-steps .steps {
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.hirng-steps .steps .item {
  display: inline-flex;
  margin-right: 0;
  text-align: left;
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}
.hirng-steps .steps .item::after{
  display: none;
}
.technologies-banner {
  padding-bottom: 0;
}
.technologies-banner .content {
  padding-right: 0;
  padding-top: 0px;
}
}
@media screen and (max-width:575px){
  .services-list ul li{
    width: 100%;
  }
  .trusted-section .video {
    height: 400px;
}
.trusted-section .video video{
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.counter-section {
  padding: 30px 0;
}
.footer{
  padding-top: 30px;
}
.footer ul{
  margin-bottom: 30px;
  margin-top: 10px;
}
}


.errors{
  color: red;
}